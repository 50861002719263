import * as THREE from "three";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { toRaw } from "vue";
import API from "@/api/API";
import { InstancedMesh2 } from "@three.ez/instanced-mesh";
import {
  RESTRICTED_AREA_COLOR,
  RENDERING_ORDER,
  BLACK,
  ZOOM_FACTOR,
} from "../constants";
import trashRed from "@/assets/model/trash_red.svg";

export const addRestrictedAreaPoint = async function (event) {
  event.preventDefault();

  if (this.disableClick(event)) return;

  // clicked outside
  if (!this.selectedArea) {
    this.disableRestrictedAreaMode();
    this.toggleActive(2);
    return;
  }

  // clicked on a different solar group
  if (this.previousSolarArea.plane.id !== this.selectedArea.plane.id) {
    this.toggleActive(2);
    return;
  }

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObject(this.selectedArea.plane);

  if (intersects.length < 1) return;

  this.disablePointDragModeForRestrictedAreas();

  const restrictedAreas = this.selectedArea.restrictedAreas;

  // check for duplicate points
  if (
    restrictedAreas.length > 0 &&
    restrictedAreas[restrictedAreas.length - 1].points.length > 0 &&
    restrictedAreas[restrictedAreas.length - 1].points.length < 2
  ) {
    let pointIntersects = this.raycaster.intersectObjects(
      restrictedAreas[restrictedAreas.length - 1].points
    );
    if (pointIntersects.length > 0) {
      if (restrictedAreas[restrictedAreas.length - 1].closed);
      return;
    }
  }
  if (
    restrictedAreas.length > 0 &&
    !restrictedAreas[restrictedAreas.length - 1].closed
  ) {
    const lastArea = restrictedAreas[restrictedAreas.length - 1];
    if (lastArea.tempLine) {
      const solidLinePoints = [
        lastArea.firstPoint.position,
        this.checkForClosedArea(lastArea)
          ? lastArea.points[0].position
          : lastArea.tempPoint.position,
      ];
      const solidLine = this.createReactiveThickLine(
        solidLinePoints,
        4.0,
        false,
        false,
        RESTRICTED_AREA_COLOR
      );

      this.removeDashedLine();
      this.scene.add(solidLine);

      lastArea.tempLine = null;
      if (lastArea.lines) {
        lastArea.lines.push({
          line: solidLine,
          firstPoint: lastArea.firstPoint,
          secondPoint: this.checkForClosedArea(lastArea)
            ? lastArea.points[0]
            : lastArea.tempPoint,
        });
      } else {
        lastArea.lines = [
          {
            line: solidLine,
            firstPoint: lastArea.firstPoint,
            secondPoint: this.checkForClosedArea(lastArea)
              ? lastArea.points[0]
              : lastArea.tempPoint,
          },
        ];
      }
    }
  }
  // check for closed area
  if (
    restrictedAreas.length > 0 &&
    restrictedAreas[restrictedAreas.length - 1].points.length > 2 &&
    !restrictedAreas[restrictedAreas.length - 1].closed
  ) {
    let pointIntersects = this.raycaster.intersectObject(
      restrictedAreas[restrictedAreas.length - 1].points[0]
    );
    if (
      pointIntersects.length > 0 ||
      restrictedAreas[restrictedAreas.length - 1].closeArea
    ) {
      this.drawPlaneForRestrictedArea(
        restrictedAreas[restrictedAreas.length - 1].points
      );

      return;
    }
  }

  if (
    restrictedAreas.length > 0 &&
    restrictedAreas[restrictedAreas.length - 1].points.length > 0
  ) {
    let pointIntersects = this.raycaster.intersectObjects(
      restrictedAreas[restrictedAreas.length - 1].points
    );
    if (pointIntersects.length > 0) return;
  }

  let o = intersects[0];

  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const dot = this.createReactivePoint(
    pIntersect,
    RESTRICTED_AREA_COLOR,
    this.isFirstPoint
  );

  const tempPoint = this.createNonReactiveAreaPoint(
    pIntersect,
    RESTRICTED_AREA_COLOR
  );

  if (
    restrictedAreas.length > 0 &&
    !restrictedAreas[restrictedAreas.length - 1].closed
  ) {
    restrictedAreas[restrictedAreas.length - 1].points.push(dot);
  } else {
    restrictedAreas.push({ points: [dot], closed: false });
  }

  let restrictedArea = restrictedAreas[restrictedAreas.length - 1];

  restrictedArea.firstPoint = dot;
  restrictedArea.tempPoint = tempPoint;

  if (
    restrictedArea.lines &&
    restrictedArea.lines[restrictedArea.lines.length - 1].firstPoint
  ) {
    restrictedArea.lines[restrictedArea.lines.length - 1].secondPoint = dot;
  }

  const firstPoint = new THREE.Vector3();
  const secondPoint = new THREE.Vector3();
  restrictedArea.firstPoint.getWorldPosition(firstPoint);
  restrictedArea.tempPoint.getWorldPosition(secondPoint);
  let points = [firstPoint, secondPoint];

  let newLine = this.createReactiveThickLine(
    points,
    4.0,
    true,
    false,
    RESTRICTED_AREA_COLOR
  );

  this.dashedMeasurementLine = newLine;
  restrictedArea.tempLine = newLine;
  this.scene.add(dot);
};

export const updatePreliminaryPointPositionForRestrictedArea = function (
  event
) {
  if (this.shouldThrottle()) return;

  let intersects = [];
  if (
    this.selectedArea &&
    this.selectedArea.restrictedAreas.length > 0 &&
    !this.selectedArea.restrictedAreas[
      this.selectedArea.restrictedAreas.length - 1
    ].closed &&
    !this.inMagenticField
  ) {
    this.setMousePosition(event);
    intersects = this.raycaster.intersectObject(this.selectedArea.plane);

    if (intersects.length < 1) return;
    let o = intersects[0];
    let pIntersect = o.point.clone();
    this.scene.worldToLocal(pIntersect);
    const area =
      this.selectedArea.restrictedAreas[
        this.selectedArea.restrictedAreas.length - 1
      ];

    let marker = area.tempPoint;
    marker.position.x = pIntersect.x;
    marker.position.y = pIntersect.y;
    marker.position.z = pIntersect.z;
    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    area.firstPoint.getWorldPosition(firstPoint);
    area.tempPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];

    if (this.dashedMeasurementLine) {
      this.updateLinePosition(this.dashedMeasurementLine, points);
    }

    const line = this.dashedMeasurementLine;
    const lineObject = this.scene.getObjectById(line.id);
    if (!lineObject) this.scene.add(toRaw(line));

    const numPoints = area.points.length;
    if (numPoints > 2) {
      const areaFirstDot = new THREE.Vector3();
      area.points[0].getWorldPosition(areaFirstDot);
      const endingPoints = [areaFirstDot, secondPoint];

      if (this.restrictedAreaEndingLine) {
        this.updateLinePosition(this.restrictedAreaEndingLine, endingPoints);
      } else {
        let newDottedLine = this.createReactiveThickLine(
          endingPoints,
          4.0,
          true,
          true,
          RESTRICTED_AREA_COLOR
        );
        this.restrictedAreaEndingLine = newDottedLine;
        this.scene.add(newDottedLine);
      }
    }
  } else if (this.selectedArea) {
    this.setMousePosition(event);

    const instancedMeshes = this.selectedArea.restrictedAreas
      .filter((area) => area.closed && area.instancedMesh)
      .map((area) => area.instancedMesh);

    intersects = this.raycaster.intersectObjects(instancedMeshes, false);
  }
  return intersects;
};

export const stickMousePointerToDotForRestrictedArea = function (event) {
  // disable point placing when clicking outside the model
  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);
  if (
    this.selectedArea &&
    this.raycaster.intersectObject(this.selectedArea.plane).length > 0 &&
    !this.mouseOverPoint
  ) {
    this.changeCursorToCrosshair();
  }

  if (
    !this.selectedArea ||
    this.selectedArea.restrictedAreas.length === 0 ||
    this.selectedArea.restrictedAreas[
      this.selectedArea.restrictedAreas.length - 1
    ].points.length === 0 ||
    this.selectedArea.restrictedAreas[
      this.selectedArea.restrictedAreas.length - 1
    ].closed
  )
    return;

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) return;
  let o = intersects[0];
  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const currentArea =
    this.selectedArea.restrictedAreas[
      this.selectedArea.restrictedAreas.length - 1
    ];

  const firstDot = currentArea?.points[0];
  const secondDot = currentArea?.points[1];
  const thirdDot = currentArea?.points[2];
  const lastDot = currentArea?.points[currentArea.points.length - 1];

  const distance = firstDot.position.distanceTo(pIntersect);
  const threshold = 0.25;

  if (firstDot && secondDot && thirdDot) {
    if (distance <= threshold) {
      this.selectedPoint = firstDot;
      this.renderer.domElement.style.cursor = `none`;
      this.selectedArea.restrictedAreas[
        this.selectedArea.restrictedAreas.length - 1
      ].closeArea = true;
      this.showSnapIcon();
      if (this.restrictedAreaEndingLine)
        this.restrictedAreaEndingLine.visible = false;
      this.inMagenticField = true;

      const firstPoint = new THREE.Vector3();
      const secondPoint = new THREE.Vector3();
      firstDot.getWorldPosition(firstPoint);
      lastDot.getWorldPosition(secondPoint);
      let points = [firstPoint, secondPoint];
      this.updateLinePosition(this.dashedMeasurementLine, points);
    } else {
      this.changeCursorToCrosshair();

      this.selectedArea.restrictedAreas[
        this.selectedArea.restrictedAreas.length - 1
      ].closeArea = false;
      if (this.selectedPoint) {
        if (this.restrictedAreaEndingLine)
          this.restrictedAreaEndingLine.visible = true;
        this.inMagenticField = false;
        this.hideSnapIcon();

        this.selectedPoint = null;
      }
    }
  } else {
    this.changeCursorToCrosshair();
    if (this.selectedPoint) {
      if (this.restrictedAreaEndingLine)
        this.restrictedAreaEndingLine.visible = true;
      this.inMagenticField = false;
      this.selectedPoint = null;
      this.hideSnapIcon();
    }
  }
};

export const drawPlaneForRestrictedArea = async function (
  points,
  createArea = true
) {
  this.resetUndoStack();
  this.resetRedoStack();

  this.removeDashedLine();

  // redraw area outline
  const vectorPoints = points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  const restrictedAreaEdges = [];
  for (let i = 0; i < vectorPoints.length; i++) {
    const nextIndex = (i + 1) % vectorPoints.length;
    restrictedAreaEdges.push([vectorPoints[i], vectorPoints[nextIndex]]);
  }

  if (this.restrictedAreaEndingLine)
    this.removeObjectFromScene(this.restrictedAreaEndingLine);

  this.restrictedAreaEndingLine = null;

  const projectedPoints = this.projectPointsOnPlane(
    points,
    this.selectedArea.trianglePlane
  );

  const area =
    this.selectedArea.restrictedAreas[
      this.selectedArea.restrictedAreas.length - 1
    ];
  this.replaceRestrictedAreaPointsWithInstancedMesh(area);

  this.projectLinesOnPlane(area.lines, this.selectedArea.trianglePlane);

  const geometry = new THREE.BufferGeometry();

  const vertices = new Float32Array(projectedPoints);

  const triangleIndices = this.getTriangleIndices(
    points,
    this.getAxisDifferences(points.map((point) => point.position))
  );
  const indices = [].concat(...triangleIndices);

  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex(new THREE.Uint16BufferAttribute(indices, 1));

  const material = new THREE.MeshBasicMaterial({
    color: RESTRICTED_AREA_COLOR,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.5,
  });

  this.combineAreaLines(area, RESTRICTED_AREA_COLOR);

  const plane = new THREE.Mesh(geometry, material);
  plane.material.depthTest = false;
  plane.renderOrder = RENDERING_ORDER.SOLAR_PLANE;

  const trashCan = document.createElement("img");
  trashCan.src = trashRed;
  trashCan.style = "cursor:pointer; width: 25px; pointer-events: all;";
  trashCan.dataset.index = this.selectedArea.restrictedAreas.length;

  trashCan.addEventListener("pointerdown", (event) => {
    const index = event.currentTarget.dataset.index;
    this.removeSelectedRestrictedArea(event, index);
  });

  const trashCanDiv = document.createElement("div");
  trashCanDiv.style = "pointer-events: all; cursor: pointer;";
  trashCanDiv.append(trashCan);

  let label = new CSS2DObject(trashCanDiv);
  const centerPoint = this.getCenterPointFromVectors(vectorPoints);
  label.position.set(centerPoint.x, centerPoint.y, centerPoint.z);
  label.layers.set(0);
  label.renderOrder = RENDERING_ORDER.MEASUREMENT_AREA_LABEL;
  this.scene.add(label);

  area.plane = plane;
  area.label = label;
  area.closed = true;
  area.closeArea = false;
  area.indices = indices;
  area.edges = restrictedAreaEdges;

  const restrictedAreaIndex = this.selectedArea?.restrictedAreas?.length;
  this.selectedArea.restrictedAreas[restrictedAreaIndex - 1] = {
    ...this.selectedArea.restrictedAreas[restrictedAreaIndex - 1],
    index: restrictedAreaIndex - 1,
  };

  this.scene.add(plane);

  // Get the lines of the solar group
  const solarGroupLines = this.selectedArea.lines || [];
  const restrictedAreaLines = area.lines || [];

  // Function to check if two line segments intersect
  function checkLineIntersection(line1, line2) {
    const start1 = line1.firstPoint.position;
    const end1 = line1.secondPoint.position;
    const start2 = line2.firstPoint.position;
    const end2 = line2.secondPoint.position;

    const denominator =
      (end2.z - start2.z) * (end1.x - start1.x) -
      (end2.x - start2.x) * (end1.z - start1.z);
    if (denominator === 0) return false; // Lines are parallel

    const ua =
      ((end2.x - start2.x) * (start1.z - start2.z) -
        (end2.z - start2.z) * (start1.x - start2.x)) /
      denominator;
    const ub =
      ((end1.x - start1.x) * (start1.z - start2.z) -
        (end1.z - start1.z) * (start1.x - start2.x)) /
      denominator;

    if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
      const intersection = new THREE.Vector3(
        start1.x + ua * (end1.x - start1.x),
        start1.y + ua * (end1.y - start1.y),
        start1.z + ua * (end1.z - start1.z)
      );
      return intersection;
    }

    return false;
  }

  // Check for intersections between restricted area lines and solar group lines
  let intersectionFound = false;
  restrictedAreaLines.forEach((restrictedLine) => {
    solarGroupLines.forEach((solarLine) => {
      const intersection = checkLineIntersection(restrictedLine, solarLine);
      if (intersection) {
        // Handle the intersection as needed
        intersectionFound = true;
      }
    });
  });
  if (intersectionFound) {
    this.hideSnapIcon(true);
    const lastRestrictedArea = this.selectedArea.restrictedAreas.pop();
    this.removePoints(lastRestrictedArea.points);
    this.removeArrayFromScene(
      lastRestrictedArea.lines.map((line) => line.line)
    );
    if (lastRestrictedArea.plane) {
      this.removeObjectFromScene(lastRestrictedArea.plane);
    }
    if (lastRestrictedArea.label) {
      this.removeObjectFromScene(lastRestrictedArea.label);
    }
    return;
  }

  this.checkPanelsInSolarArea(this.selectedArea);

  if (this.selectedArea.restrictedAreas.length > 0)
    this.checkIndividualPanelIntersectionWithRestrictedArea(
      this.selectedArea,
      this.selectedArea.restrictedAreas.length - 1
    );

  this.hideSnapIcon(true);

  this.checkPanelNumberAndVisibility(this.selectedArea);

  this.enablePointDragModeForRestrictedAreas();

  this.hideAreaLabelIcons();

  if (!this.anonymousUser && createArea && !this.sample) {
    try {
      const position = [];

      this.selectedArea.points.forEach((element) => {
        const positionObject = {
          x: parseFloat(element.position.x.toFixed(3)),
          y: parseFloat(element.position.y.toFixed(3)),
          z: parseFloat(element.position.z.toFixed(3)),
        };
        position.push(positionObject);
      });

      const restrictedAreaPoints = [];

      if (this.selectedArea.restrictedAreas.length) {
        this.selectedArea.restrictedAreas.forEach((area) => {
          const areaPoints = area.points.map((point) => ({
            x: parseFloat(point.position.x.toFixed(3)),
            y: parseFloat(point.position.y.toFixed(3)),
            z: parseFloat(point.position.z.toFixed(3)),
          }));

          restrictedAreaPoints.push(areaPoints);
        });
      }

      const { data } = await this.createRestrictedAreaObject(
        restrictedAreaPoints,

        this.selectedArea
      );
      this.selectedArea.restrictedAreas[
        this.selectedArea.restrictedAreas.length - 1
      ].id = data;
    } catch (e) {
      console.log(e, "trh error");
    }
  }
};

export const createRestrictedAreaObject = async function (
  restrictedAreaPositions,
  area
) {
  if (this.sample) return;

  const data = {
    restricted_areas: restrictedAreaPositions,
  };

  try {
    // Perform API request
    const response = await API.airteam3DViewer.patchSolarAreaObject(
      data,
      area.id
    );

    // Ensure the response is as expected

    // Assuming response contains the data needed
    return response.data; // Modify based on actual response structure
  } catch (error) {
    console.error("Error occurred while updating solar group object:", error);
    throw error; // Rethrow the error to be caught by calling code
  }
};

export const removeUnfinishedRestrictedAreas = function () {
  const solarGroups = this.areas.filter(
    (area) =>
      area.type === "SOLAR_GROUP" &&
      area.restrictedAreas &&
      area.restrictedAreas.length > 0
  );

  for (let area of solarGroups) {
    const restrictedArea =
      area.restrictedAreas[area.restrictedAreas.length - 1];
    if (restrictedArea.closed) continue;

    this.removePoints(restrictedArea.points);

    if (this.restrictedAreaEndingLine) {
      this.removeObjectFromScene(this.restrictedAreaEndingLine);
      this.restrictedAreaEndingLine = null;
    }
    this.removeDashedLine();

    if (restrictedArea.lines) {
      this.removeArrayFromScene(restrictedArea.lines.map((line) => line.line));
    }

    area.restrictedAreas = area.restrictedAreas.slice(
      0,
      area.restrictedAreas.length - 1
    );
  }
};

export const showRestrictedAreaDetails = function (solarArea) {
  if (solarArea.restrictedAreas?.length === 0) return;

  solarArea.restrictedAreas
    .filter((area) => area.closed)
    .forEach((area) => {
      const label = area.label;
      const secondColumn = label.element.children[0];
      if (area.combinedLine) {
        area.combinedLine.visible = true;
      }
      secondColumn.style =
        "margin: auto; margin-left: 8px; width: 25px; height: 25px";

      if (area.instancedMesh) {
        area.instancedMesh.visible = true;
      }
    });
};

export const hideRestrictedAreaDetails = function (solarGroup) {
  solarGroup.restrictedAreas
    .filter((area) => area.closed)
    .forEach((area) => {
      const label = area.label;
      if (area.combinedLine) {
        area.combinedLine.visible = false;
      }
      const secondColumn = label?.element.children[0];
      if (secondColumn) {
        secondColumn.style = "margin: auto; margin-left: 8px; display: none;";
      }

      if (area.instancedMesh) {
        area.instancedMesh.visible = false;
      }
    });
};

// delete all restricted area inside solar group
export const removeAllRestrictedAreas = function (area) {
  // Loop through and remove all restricted areas
  area.restrictedAreas.forEach((area) => {
    this.removeObjectFromScene(area.plane);
    this.removeObjectFromScene(area.label);
    this.removeObjectFromScene(area.instancedMesh);
    this.removeObjectFromScene(area.combinedLine);
  });
};

export const detectRestrictedArea = function (event) {
  event.preventDefault();

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObjects(
    this.selectedArea.restrictedAreas
      .filter((area) => area.plane)
      .map((area) => area.plane)
  );
  if (intersects.length < 1) return;

  let areaToExpand;

  if (intersects.length == 2) {
    let objectOne = intersects[0].object;
    let objectTwo = intersects[1].object;
    if (
      objectOne.geometry.boundingSphere.radius <
      objectTwo.geometry.boundingSphere.radius
    ) {
      areaToExpand = objectOne;
    } else {
      areaToExpand = objectTwo;
    }
  } else {
    areaToExpand = intersects[0].object;
  }

  const clickedArea = this.selectedArea.restrictedAreas.find(
    (area) => area.plane && area.plane.id === areaToExpand.id
  );

  return clickedArea;
};

export const removeRestrictedArea = function (area) {
  this.removeObjectFromScene(area.plane);
  this.removeObjectFromScene(area.label);
  this.removeObjectFromScene(area.instancedMesh);
  this.removeObjectFromScene(area.combinedLine);

  this.selectedArea.restrictedAreas = this.selectedArea.restrictedAreas.filter(
    (restrictedArea) => restrictedArea !== area
  );

  let restrictedAreaPoints = [];
  this.selectedArea.restrictedAreas.forEach((area) => {
    const areaPoints = area.points.map((point) => ({
      x: parseFloat(point.position.x.toFixed(3)),
      y: parseFloat(point.position.y.toFixed(3)),
      z: parseFloat(point.position.z.toFixed(3)),
    }));

    restrictedAreaPoints.push(areaPoints);
  });
  this.createRestrictedAreaObject(restrictedAreaPoints, this.selectedArea);
  this.checkPanelsInSolarArea(this.selectedArea);
  this.checkPanelNumberAndVisibility(this.selectedArea);
};

export const removeSelectedRestrictedArea = function (e, index) {
  let areaToDelete = this.detectRestrictedArea(e);
  if (!areaToDelete) {
    areaToDelete = this.selectedArea.restrictedAreas[index - 1];
  }

  if (!areaToDelete) return;

  let areaIndex;
  for (let i = 0; i < this.selectedArea.restrictedAreas.length; i++) {
    let areaObj = this.selectedArea.restrictedAreas[i];
    if (areaObj.plane.uuid === areaToDelete.plane.uuid) {
      areaIndex = i;
      break;
    }
  }

  this.removeObjectFromScene(areaToDelete.plane);
  this.removeObjectFromScene(areaToDelete.label);
  this.removeObjectFromScene(areaToDelete.instancedMesh);
  this.removeObjectFromScene(areaToDelete.combinedLine);

  this.selectedArea.restrictedAreas.splice(areaIndex, 1);

  const restrictedAreaPoints = [];

  if (this.selectedArea.restrictedAreas.length) {
    this.selectedArea.restrictedAreas.forEach((area) => {
      const areaPoints = area.points.map((point) => ({
        x: parseFloat(point.position.x.toFixed(3)),
        y: parseFloat(point.position.y.toFixed(3)),
        z: parseFloat(point.position.z.toFixed(3)),
      }));

      restrictedAreaPoints.push(areaPoints);
    });
  }
  if (this.selectedArea.instancedMesh)
    this.checkPanelsInSolarArea(this.selectedArea);

  this.createRestrictedAreaObject(restrictedAreaPoints, this.selectedArea);
  this.checkPanelNumberAndVisibility(this.selectedArea);

  if (this.active === 6) this.hideAreaLabelIcons();
};

export const enablePointDragModeForRestrictedAreas = function () {
  if (!this.selectedArea?.restrictedAreas) return;

  // Add mouse event listeners directly to the renderer's domElement
  this.renderer.domElement.addEventListener(
    "mousedown",
    this.dragStartedForRestrictedArea
  );
};

export const disablePointDragModeForRestrictedAreas = function () {
  if (!this.selectedArea?.restrictedAreas) return;

  // Remove event listeners
  this.renderer.domElement.removeEventListener(
    "mousedown",
    this.dragStartedForRestrictedArea
  );
  this.renderer.domElement.removeEventListener(
    "mousemove",
    this.dragAreaPointForRestrictedArea
  );
  this.renderer.domElement.removeEventListener(
    "mouseup",
    this.dragEndedForRestrictedArea
  );
};

export const dragAreaPointForRestrictedArea = function (e) {
  if (!this.dragOn) return;

  this.setMousePosition(e);

  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  const area = this.draggedRestrictedAreaPoint.area;
  const instancedMesh = area.instancedMesh;
  const pointIndex = this.draggedRestrictedAreaPoint.index;

  if (intersects.length > 0) {
    const intersectionPoint = new THREE.Vector3();
    this.raycaster.ray.intersectPlane(
      this.selectedArea.infinitePlane,
      intersectionPoint
    );

    const transformationMatrix = new THREE.Matrix4();
    instancedMesh.getMatrixAt(pointIndex, transformationMatrix);
    transformationMatrix.setPosition(intersectionPoint);
    instancedMesh.setMatrixAt(pointIndex, transformationMatrix);

    instancedMesh.instanceMatrix.needsUpdate = true;
    instancedMesh.computeBVH();

    // Update the point position
    if (area.points[pointIndex]) {
      area.points[pointIndex].position.copy(intersectionPoint);
    }
  }

  // Redraw the area with updated point
  this.reDrawRestrictedAreaFromPoint(area);
};

// Helper function to project a point onto a plane
export const projectPointOntoPlane = function (point, planePoint, planeNormal) {
  const pointToPlane = new THREE.Vector3().subVectors(point, planePoint);
  const distance = pointToPlane.dot(planeNormal);
  return point.clone().sub(planeNormal.clone().multiplyScalar(distance));
};

export const reDrawRestrictedAreaFromPoint = function () {
  let currentRestrictedArea = this.draggedRestrictedAreaPoint.area;
  const pointIndex = this.draggedRestrictedAreaPoint.index;

  if (!currentRestrictedArea) return;

  // redraw area outline
  const vectorPoints = currentRestrictedArea.points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  const projectedPoints = this.projectPointsOnPlane(
    currentRestrictedArea.points,
    this.selectedArea.trianglePlane
  );

  const flatPoints = [].concat(...projectedPoints);

  const geometry = currentRestrictedArea.plane.geometry;
  const vertices = new Float32Array(flatPoints);

  // Get triangle indices
  const triangleIndices = this.getTriangleIndices(
    currentRestrictedArea.points,
    this.getAxisDifferences(
      currentRestrictedArea.points.map((point) => point.position)
    )
  );
  const indices = [].concat(...triangleIndices);

  // Update geometry
  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex(new THREE.Uint16BufferAttribute(indices, 1));

  geometry.computeBoundingBox();
  geometry.computeBoundingSphere();

  // Update edges
  currentRestrictedArea.edges = vectorPoints.map((point, index) => {
    const nextIndex = (index + 1) % vectorPoints.length;
    return [point, vectorPoints[nextIndex]];
  });
  // snap dragged point to the mesh

  // Update combined line
  if (currentRestrictedArea.combinedLine) {
    this.updateClosedLinePosition({
      line: currentRestrictedArea.combinedLine,
      points: currentRestrictedArea.points,
    });
  }

  // Update label position
  if (currentRestrictedArea.label) {
    const centerPoint = this.getCenterPointFromVectors(vectorPoints);
    currentRestrictedArea.label.position.set(
      centerPoint.x,
      centerPoint.y,
      centerPoint.z
    );
  }

  return currentRestrictedArea;
};

export const enableAddRestrictedAreaPoint = function () {
  document.addEventListener("click", this.addRestrictedAreaPoint, false);
};

export const disableAddRestrictedAreaPoint = function () {
  document.removeEventListener("click", this.addRestrictedAreaPoint, false);

  document.removeEventListener("mousedown", this.dragStartedForRestrictedArea);
  document.removeEventListener(
    "mousemove",
    this.dragAreaPointForRestrictedArea
  );
  document.removeEventListener("mouseup", this.dragEndedForRestrictedArea);
};

export const holdIndividualPanelCursorChanges = function () {
  if (this.active === 2) {
    document.removeEventListener(
      "mousemove",
      this.updateCursorForIndividualPlacement,
      false
    );
    document.removeEventListener(
      "mousemove",
      this.detectIndividualPanels,
      false
    );
  }
};

export const unHoldIndividualPanelCursorChanges = function () {
  if (this.active === 2) {
    document.addEventListener("mousemove", this.detectIndividualPanels, false);
  }
};

export const dragStartedForRestrictedArea = function (e) {
  this.setMousePosition(e);
  if (!this.selectedArea) return;
  const restrictedAreas = this.selectedArea.restrictedAreas;
  const points = restrictedAreas
    .map((area) => area.instancedMesh)
    .filter(Boolean);

  const pointIntersects = this.raycaster.intersectObjects(points, true);
  if (pointIntersects.length === 0) return;
  const intersectionPoint = pointIntersects[0];

  // Store dragged point information in a structured way
  this.draggedRestrictedAreaPoint = {
    area: restrictedAreas.find(
      (area) => area.instancedMesh.id === intersectionPoint.object.id
    ),
    index: intersectionPoint.instanceId,
  };

  // Get original position
  const originalPosition = new THREE.Vector3();
  const transformationMatrix = new THREE.Matrix4();

  this.draggedRestrictedAreaPoint.area.instancedMesh.getMatrixAt(
    this.draggedRestrictedAreaPoint.index,
    transformationMatrix
  );

  transformationMatrix.decompose(
    originalPosition,
    new THREE.Quaternion(),
    new THREE.Vector3()
  );

  // Store positions for reference
  this.draggedRestrictedAreaPoint.lastPosition = {
    x: originalPosition.x,
    y: originalPosition.y,
    z: originalPosition.z,
  };

  this.draggedRestrictedAreaPoint.originalPosition = {
    x: originalPosition.x,
    y: originalPosition.y,
    z: originalPosition.z,
  };

  // Clean up and setup event listeners
  if (this.active === 6) this.disableAddRestrictedAreaPoint();

  if (this.selectedArea) {
    this.selectedArea.moveGridLabel.visible = false;
    this.selectedArea.rotateGridLabel.visible = false;
  }

  if (this.draggedRestrictedAreaPoint.area.label) {
    this.draggedRestrictedAreaPoint.area.label.visible = false;
  }

  document.addEventListener("mousemove", this.dragAreaPointForRestrictedArea);
  document.addEventListener("mouseup", this.dragEndedForRestrictedArea);

  this.holdIndividualPanelCursorChanges();

  this.mouseOverPoint = true;
  this.dragOn = true;
};

export const dragEndedForRestrictedArea = function () {
  const { area } = this.draggedRestrictedAreaPoint;

  // Remove drag-related event listeners
  document.removeEventListener(
    "mousemove",
    this.dragAreaPointForRestrictedArea
  );
  document.removeEventListener("mouseup", this.dragEndedForRestrictedArea);

  // Reset state
  this.draggedRestrictedAreaPoint = null;

  // Update backend
  const restrictedAreaPoints = [];
  this.selectedArea.restrictedAreas.forEach((area) => {
    const areaPoints = area.points.map((point) => ({
      x: parseFloat(point.position.x.toFixed(3)),
      y: parseFloat(point.position.y.toFixed(3)),
      z: parseFloat(point.position.z.toFixed(3)),
    }));
    restrictedAreaPoints.push(areaPoints);
  });

  // Make the label visible again
  if (area.label) {
    area.label.visible = true;
  }

  // Update backend
  this.createRestrictedAreaObject(restrictedAreaPoints, this.selectedArea);

  // Check for restricted areas outside solar area and remove them
  if (this.selectedArea.restrictedAreas) {
    this.selectedArea.restrictedAreas.forEach((restrictedArea) => {
      const restrictedAreaPoints = restrictedArea.points.map(
        (point) =>
          new THREE.Vector3(
            point.position.x,
            point.position.y,
            point.position.z
          )
      );

      if (
        !this.isRectangleWithinPoints(
          restrictedAreaPoints,
          restrictedArea.edges,
          this.selectedArea.innerPlane.points,
          this.selectedArea.innerPlane.edges,
          this.selectedArea.simulatedCamera
        )
      ) {
        this.removeRestrictedArea(restrictedArea);
      }
    });
  }

  this.resetUndoStack();
  this.resetRedoStack();

  this.checkPanelsInSolarArea(this.selectedArea);

  for (let i = 0; i < this.selectedArea.restrictedAreas.length; i++) {
    this.checkIndividualPanelIntersectionWithRestrictedArea(
      this.selectedArea,
      i
    );
  }

  this.checkPanelNumberAndVisibility(this.selectedArea);

  // Re-enable core functionality
  if (this.active === 6) {
    // Re-enable restricted area mode
    setTimeout(() => {
      this.enablePointDragModeForRestrictedAreas();
      this.enableAddRestrictedAreaPoint();
      this.hideAreaLabelIcons();
    }, 0);
  } else {
    document.addEventListener("click", this.addPoint, false);
    window.addEventListener("mousemove", this.stickMousePointerToDot);
    document.addEventListener("mousemove", this.detectModelIntersection, false);
    document.addEventListener("keydown", this.removeLastPoint, false);
  }

  this.unHoldIndividualPanelCursorChanges();

  this.mouseOverPoint = false;
  this.dragOn = false;
};

export const hideRestrictedAreas = function () {
  this.areas.forEach((area) => {
    this.hideRestrictedArea(area);
  });
};

export const hideRestrictedArea = function (area) {
  if (area.restrictedAreas) {
    this.hideRestrictedAreaDetails(area);
    area.restrictedAreas.forEach((restrictedArea) => {
      restrictedArea.plane.visible = false;
    });
  }
};
export const showRestrictedAreas = function () {
  this.areas.forEach((area) => {
    this.shoWRestrictedArea(area);
  });
};
export const shoWRestrictedArea = function (area) {
  if (area.restrictedAreas) {
    area.restrictedAreas.forEach((restrictedArea) => {
      if (restrictedArea.plane) {
        restrictedArea.plane.visible = true;
      }
    });
    if (area.inMagenticField) {
      this.checkPanelsInSolarArea(area);
    }
  }
};

export function processRestrictedAreas(solarGroup) {
  const tempRestrictedAreas = this.areas[this.areas.length - 1].restrictedAreas;
  this.areas[this.areas.length - 1].restrictedAreas = [];

  if (tempRestrictedAreas) {
    tempRestrictedAreas.forEach((areaPoints) => {
      // Create points array for the restricted area
      const points = areaPoints.map((point) => {
        return this.createReactivePoint(
          new THREE.Vector3(point.x, point.y, point.z),
          RESTRICTED_AREA_COLOR,
          false
        );
      });

      // Create lines between points
      const lines = [];
      for (let i = 0; i < points.length; i++) {
        const firstPoint = points[i];
        const secondPoint = points[(i + 1) % points.length];

        const solidLine = this.createReactiveThickLine(
          [firstPoint.position, secondPoint.position],
          4.0,
          false,
          false,
          RESTRICTED_AREA_COLOR
        );

        lines.push({
          line: solidLine,
          firstPoint: firstPoint,
          secondPoint: secondPoint,
        });

        this.scene.add(solidLine);
      }

      // Create the restricted area object with all necessary properties
      const restrictedArea = {
        points: points,
        lines: lines,
        closed: true,
        tempLine: null,
        closeArea: false,
      };

      // Add the restricted area to the current area
      this.areas[this.areas.length - 1].restrictedAreas.push(restrictedArea);

      // Draw the plane for restricted area
      this.drawPlaneForRestrictedArea(points, false);
    });

    // Enable drag controls after all areas are processed
    this.enablePointDragModeForRestrictedAreas();
  }
}

export const disableRestrictedAreaMode = function () {
  this.modes.find((mode) => mode.value === 6).disableFunction();
};

export const hideAreaLabelIcons = function () {
  if (this.selectedArea && this.selectedArea.moveGridLabel) {
    this.selectedArea.moveGridLabel.visible = false;
    this.selectedArea.rotateGridLabel.visible = false;
  }
};

export const showAreaLabelIcons = function () {
  this.checkPanelNumberAndVisibility(this.selectedArea);
};

export const replaceRestrictedAreaPointsWithInstancedMesh = function (
  restrictedArea
) {
  if (!restrictedArea?.points?.length) {
    console.warn("No points to replace");
    return;
  }

  const geometry = new THREE.SphereGeometry(0.1, 32, 32);
  const material = this.createShaderMaterialForInstancedMesh(
    RESTRICTED_AREA_COLOR,
    BLACK,
    0.25
  );

  const instancedMesh = new InstancedMesh2(
    this.renderer,
    restrictedArea.points.length,
    geometry,
    material
  );
  instancedMesh.material.depthTest = false;
  instancedMesh.renderOrder = RENDERING_ORDER.OUTER_POINT;
  instancedMesh.computeBVH();

  const tempPosition = new THREE.Vector3();
  const transformationMatrix = new THREE.Matrix4();

  // Set positions for instanced mesh
  for (let i = 0; i < restrictedArea.points.length; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);
    transformationMatrix.decompose(
      tempPosition,
      new THREE.Quaternion(),
      new THREE.Vector3(1, 1, 1)
    );

    const pointPosition = restrictedArea.points[i].position;
    tempPosition.set(pointPosition.x, pointPosition.y, pointPosition.z);

    transformationMatrix.compose(
      tempPosition,
      new THREE.Quaternion(),
      new THREE.Vector3(1, 1, 1)
    );
    instancedMesh.setMatrixAt(i, transformationMatrix);

    // Remove original point from scene
    this.removeObjectFromScene(restrictedArea.points[i]);
  }

  // Add instanced mesh to scene
  this.scene.add(instancedMesh);

  // Store reference to instanced mesh
  restrictedArea.instancedMesh = instancedMesh;
  instancedMesh.instanceMatrix.needsUpdate = true;
  instancedMesh.computeBVH();

  return instancedMesh;
};
