<template>
  <div>
    <div class="q-px-sm">
      <p class="title">
        {{ $t("panelMenu.individualPanel") }}
        <span>
          <el-tooltip
            class="bg-tooltip"
            effect="dark"
            :content="$t('panelMenu.individualPanelInfo')"
            placement="bottom"
          >
            <img
              src="@/assets/icons/info-icon.svg"
              class="q-ml-xs q-mb-xxs cursor-pointer"
            />
          </el-tooltip>
        </span>
      </p>
      <div class="q-mt-sm">
        <p class="title--panel q-mb-sm">
          {{ $t("panelMenu.panelOrientation") }}
        </p>
        <div class="q-mr-lg" style="display: inline-block">
          <img
            v-show="individualPanelOrientation !== 'VERTICAL'"
            src="@/assets/icons/vertical-panel-inactive.svg"
            class="cursor-pointer"
            @click="() => enableIndividualPlacementMode('VERTICAL')"
          />
          <img
            v-show="individualPanelOrientation === 'VERTICAL'"
            src="@/assets/icons/vertical-panel-active.svg"
            class="cursor-pointer"
            @click="disableIndividualPlacementMode"
          />
        </div>
        <div style="display: inline-block; vertical-align: bottom">
          <img
            v-show="individualPanelOrientation !== 'HORIZONTAL'"
            src="@/assets/icons/horizontal-panel-inactive.svg"
            class="cursor-pointer"
            @click="() => enableIndividualPlacementMode('HORIZONTAL')"
          />
          <img
            v-show="individualPanelOrientation === 'HORIZONTAL'"
            src="@/assets/icons/horizontal-panel-active.svg"
            class="cursor-pointer"
            @click="disableIndividualPlacementMode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndividualPlacement",
  props: [
    "enableIndividualPlacementMode",
    "disableIndividualPlacementMode",
    "individualPanelOrientation",
  ],
};
</script>

<style lang="scss" scoped>
.title {
  color: white;
  font-weight: 600;
  font-size: 16px;

  &--panel {
    font-weight: 400;
  }
}

.hotkey-hint {
  display: inline-block;
  padding: 2px 8px;
  border: 1px solid white;
  border-radius: 3px;
  color: white;
  margin-left: 8px;
  cursor: default;

  &--text {
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
