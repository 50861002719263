<template>
  <div>
    <div class="q-px-sm">
      <p class="title">
        {{ $t("panelMenu.createRestrictedArea") }}
        <span>
          <el-tooltip
            class="bg-tooltip"
            effect="dark"
            :content="$t('panelMenu.restrictedAreaInfo')"
            placement="bottom"
          >
            <img
              src="@/assets/icons/info-icon.svg"
              class="q-ml-xs q-mb-xxs cursor-pointer"
            />
          </el-tooltip>
        </span>
      </p>
      <div class="q-mt-sm">
        <el-tooltip class="bg-tooltip" effect="dark" placement="right">
          <template #content>
            <div style="display: flex; align-items: center">
              <span>{{ $t("panelMenu.createRestrictedArea") }}</span>
              <div class="hotkey-hint">
                <span class="hotkey-hint--text">S</span>
              </div>
            </div>
          </template>
          <div style="display: inline">
            <img
              v-show="active !== 6"
              src="@/assets/icons/restricted-area-btn.svg"
              class="cursor-pointer"
              @click="(e) => handleCreateRestrictedArea(e)"
            />
            <img
              v-show="active === 6"
              src="@/assets/icons/restricted-area-btn-active.svg"
              class="cursor-pointer"
              @click="disableRestrictedAreaMode"
            />
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RestrictedArea",
  props: ["active", "handleCreateRestrictedArea", "disableRestrictedAreaMode"],
};
</script>

<style lang="scss" scoped>
.title {
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.hotkey-hint {
  display: inline-block;
  padding: 2px 8px;
  border: 1px solid white;
  border-radius: 3px;
  color: white;
  margin-left: 8px;
  cursor: default;

  &--text {
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
