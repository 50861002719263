<template>
  <q-dialog v-model="visible" @hide="closeModal">
    <q-card>
      <q-card-section class="row justify-between items-center">
        <div class="row items-center">
          <img src="@/assets/icons/remove-panel-warning.svg" />
          <span class="text-h6 q-ml-sm">{{
            $t("changePanelModal.title")
          }}</span>
        </div>
        <img
          class="cursor-pointer"
          src="@/assets/icons/close-white.svg"
          @click="closeModal"
        />
      </q-card-section>
      <q-card-section>
        {{ $t("changePanelModal.content") }}
      </q-card-section>

      <q-card-actions class="q-mt-md">
        <q-btn
          class="cursor-pointer"
          flat
          :label="$t('changePanelModal.cancel')"
          color="white"
          @click="closeModal"
          style="margin-right: auto"
        />
        <q-btn
          class="cursor-pointer"
          flat
          :label="$t('changePanelModal.confirm')"
          color="gree"
          @click="confirmChange"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "ConfirmChangePanelModal",
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:isVisible", "close-modal", "confirm"],
  computed: {
    visible: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.$emit("update:isVisible", value);
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    confirmChange() {
      this.$emit("confirm");
      this.$emit("update:isVisible", false);
    },
  },
};
</script>

<style scoped lang="scss">
.q-card {
  max-width: 600px;
  background: #27292c;
  color: white;
  border-radius: 12px;
}
</style>
